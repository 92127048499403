<template>
  <b-container>
    <div class="text-center mb-5">
      <h2 class="fw-bold text-uppercase">
        Investors & Partners
      </h2>
      <h3 class="subtitle_box">
      </h3>
    </div>
    <b-row>
      <TeamMemberCard firstname="Connect" lastname="Outdoors" position="www.connectpassnft.com" background="partners/img_partner01.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="" lastname="FaceX" position="www.facex.app" background="partners/img_partner02.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
      <TeamMemberCard firstname="" lastname="DizzOMA.Pro" position="www.dizzoma.com" background="partners/img_partner03.jpg" primary-color="#F3C976" secondary-color="#F3C976"></TeamMemberCard>
    </b-row>
    <b-row class="my-4">
    </b-row>
  </b-container>
</template>

<script>
import TeamMemberCard from "@/components/TeamMemberCard.vue";
export default {
  name: "InvestorsAndPartners",
  components: {
    TeamMemberCard
  }

}
</script>

<style scoped>

</style>