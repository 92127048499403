<template>
  <b-container>
    <b-row>
      <b-col sm="12">
        <div class="content-card align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/cast-vote-background-full-size.png`) + ')' }">
          <div class="mt-auto my-lg-auto ms-lg-50 text-center text-lg-start">
            <div class="title">
              Cast your vote for your favorite, it may bring you victory.
            </div>
            <div class="h5 mt-3">
              In-game currency, obtained in contests or voting, will help you pump up your professional profile and open up new opportunities for your progress .
            </div>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12" >
        <div class="content-card mirrored align-self-center d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/complete-and-get-coins.png`) + ')' }">
          <div class="mt-auto my-lg-auto me-lg-50 text-center text-lg-start">
            <div class="title">
              Compete and get coines!
            </div>
            <div class="h5 mt-3">
              Just click to challenge your oponent. The victory gives you a Call Me Out Token (CMO). The platform is designed so that players can reccieve financial benefits from all in-game mechanics.
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="px-2" sm="12" lg="6">
        <div class="content-card small align-items-baseline d-flex flex-column" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/complete-and-get-access.png`) + ')' }">
          <b-img src="./../assets/img/cards/key-icon.png" width="90"></b-img>
          <div class="title fw-bolder h5 mt-auto text-white">
            Compete and get access to the next level of contests.
          </div>
          <div class="subtitle mt-3">
            Be the one with the best expertise to win more contests. So earn more Coins (CMO)!
          </div>
        </div>
      </b-col>
      <b-col sm="12" lg="6" class="px-2">
        <div class="content-card small align-items-baseline d-flex flex-column" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/cards/become-a-mentor.png`) + ')' }">
          <b-img src="./../assets/img/cards/person-icon.png" class="align-self-auto" width="90"></b-img>
          <div class="title fw-bolder h5 mt-auto text-white">
            Become a mentor and train other users' skills, build your own community!
          </div>
          <div class="subtitle mt-3">
            Upgrade your account so you can keep<br>
            improving.
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script setup lang="ts">
</script>
