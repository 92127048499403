
import { Options, Vue } from 'vue-class-component';
import Menu from "@/components/MainMenu.vue";
import MainMenu from "@/components/MainMenu.vue";
import MainBanner from "@/components/MainBanner.vue";
import ActivitiesSlider from "@/components/ActivitiesSlider.vue";
import InformationCards from "@/components/InformationCards.vue";
import OurTeam from "@/components/OurTeam.vue";
import InvestorsAndPartners from "@/components/InvestorsAndPartners.vue";
import RoadmapStrategy from "@/components/RoadmapStrategy.vue"
import ContactUs from "@/components/ContactUs.vue";
import SiteFooter from "@/components/SiteFooter.vue";
import {RoadMapPoint} from "@/objects/RoadMapPoint";
import {TeamMember} from "@/objects/TeamMember";
import {SlideInfo} from "@/objects/SlideInfo";
import {MenuItem} from "@/objects/MenuItem";
import {SocialNetworks} from "@/objects/SocialNetworks";
@Options({
  components: {
    SiteFooter,
    ContactUs,
    InformationCards,
    ActivitiesSlider,
    MainBanner,
    MainMenu,
    Menu,
    OurTeam,
    InvestorsAndPartners,
    RoadmapStrategy
  },
})


//TODO: Fix title in roadmap

export default class App extends Vue {
  // Social networks
  socialNetworks: SocialNetworks = new SocialNetworks(
      'https://t.me/Buzzio_app',
      'https://twitter.com/buzzio_app',
      'https://discord.gg/kDN94ejz',
      'https://linkedin.com/in/buzzio-app-01477325b'
  )

  // Main menu items
  mainMenu:MenuItem[] = [
    {
      title: 'About project',
      link: '#about-project'
    },
    {
      title: 'Our team',
      link: '#our-team'
    },
    {
      title: 'Roadmap',
      link: '#roadmap'
    },
  ]

  //PROJECT SLIDES
  projectSlides:SlideInfo[] = [
    {
      title: 'Motocross',
      rank: 520000,
      award: 43,
      background_url: 'Motocross2.jpg'
    },
    {
      title: 'Football',
      rank: 16000,
      award: 34,
      background_url: 'Football.jpg'
    },
    {
      title: 'Motocross',
      rank: 7000,
      award: 25,
      background_url: 'Motocross.jpg'
    },
    {
      title: 'Fitness',
      rank: 6100,
      award: 16,
      background_url: 'Fitness.jpg'
    },
    {
      title: 'Hobby',
      rank: 4300,
      award: 7,
      background_url: 'jump.jpg'
    },
    {
      title: 'Aerobics',
      rank: 3400,
      award: 7,
      background_url: 'Free-callisthenics.jpg'
    },
    {
      title: 'Hobby',
      rank: 2500,
      award: 5,
      background_url: 'Hobby.jpg'
    },
    {
      title: 'Hobby',
      rank: 2410,
      award: 5,
      background_url: 'Hobby2.jpg'
    },
    {
      title: 'Snowboard',
      rank: 1600,
      award: 4,
      background_url: 'Snowboard.jpg'
    },
    {
      title: 'Hobby',
      rank: 700,
      award: 2,
      background_url: 'Hobby3.jpg'
    },
  ]

  // TEAM MEMBERS
  teamMembers:TeamMember[] = [
    {
      firstName: "Uladzimir",
      lastName: "Dabryian",
      position: "Co-Founder / Product Director",
      background: "UladzimirDabryian.jpg"
    },
    {
      firstName: "Oleg",
      lastName: "Apet",
      position: "Co-Founder / Product Designer",
      background: "OlegApet.jpg"
    },
    {
      firstName: "Vitalik",
      lastName: "Shalak",
      position: "Head of Dev",
      background: "VitalikShalak.jpg"
    },
  ]

  // ROADMAP
  roadMapPoints: RoadMapPoint[] = [
    {
      id: 1,
      date: 'Q1 2023 - Q2 2023',
      title: 'MVP 1',
      description: 'Marketing:<br/>' +
          '&bull; User research: interviews with CustDev, marketing campaigns, A/B tests;<br/>' +
          '&bull; Internal product tokenomics.<br/>' +
          '<br/>' +
          'Design:<br/>' +
          '&bull; UI &frasl; UX development for MVP1<br/>' +
          '<br/>' +
          'Development:<br/>' +
          '&bull; Development of a clickable MVP1 prototype for iOS',
      isCompleted: true
    },
    {
      id: 2,
      date: 'Q3 2023 - Q4 2023',
      title: 'MVP 2',
      description: 'Marketing:<br/>' +
          '&bull; PR;<br/>' +
          '&bull; Establish partnerships with the Web3 ecosystem;<br/>' +
          '&bull; Scale up user adoption;<br/>' +
          '&bull; Launch crypto-education activities for users;<br/>' +
          '&bull; Forming a community around the product;<br/>' +
          '&bull; Attracting first users with UA and testing the product.<br/>' +
          '<br/>' +
          'Development:<br/>' +
          '&bull; Backend formation;<br/>' +
          '&bull; Implementation of tokenomics and competition system;<br/>' +
          '&bull; Connecting Web3 profile to the product;<br/>' +
          '&bull; Product release on iOS.',
      isCompleted: false
    },
    {
      id: 3,
      date: 'Q1 2024',
      title: 'Version 1.0',
      description: 'Marketing:<br/>' +
          '&bull; PR;<br/>' +
          '&bull; Establish partnerships with the Web3 ecosystem;<br/>' +
          '&bull; Scale up user adoption;<br/>' +
          '&bull; Launch crypto-education activities for users.<br/>' +
          '<br/>' +
          'Development:<br/>' +
          '&bull; Preparing for high loads: deploying caching servers.<br/>',
      isCompleted: false
    },
  ]
}

