<template>
  <b-col @click="redirectIfLink" v-bind:style="this.socialLink !== null ? 'cursor: pointer':''" cols="12" lg="4" class="team-member-card">
    <div class="h-100 d-flex" v-bind:style="{ backgroundImage: 'url(' + require(`../assets/img/people-cards/` + background) + ')' }">
      <div class="d-flex flex-column mt-0 mt-auto">
        <div class="title" v-bind:style="'color:'+primaryColor">{{firstname}}<br>{{lastname}}</div>
        <div class="subtitle" v-bind:style="'color:'+secondaryColor">{{position}}</div>
      </div>
    </div>
  </b-col>
</template>

<script>
export default {
  name: "TeamMemberCard",
  props: {
    firstname: String,
    lastname: String,
    position: String,
    background: String,
    primaryColor: {
      type: String,
      default: 'black'
    },
    secondaryColor: {
      type: String,
      default: '#4A4A4AFF'
    },
    socialLink: {
      type: String,
      default: null,
      required: false
    }
  },
  methods: {
    redirectIfLink(){
      if(this.socialLink !== null){
        window.location.href = this.socialLink
      }
    }
  }
}
</script>

<style scoped>

</style>