import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import App from './App.vue'

import 'bootstrap/dist/css/bootstrap.css'
import './assets/sass/bootstrap-rewrites.sass'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import './assets/sass/menu.sass'
import 'vue3-carousel/dist/carousel.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

library.add(faPhone)
createApp(App)
    .use(BootstrapVue3)
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')

