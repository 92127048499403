import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainMenu = _resolveComponent("MainMenu")!
  const _component_MainBanner = _resolveComponent("MainBanner")!
  const _component_ActivitiesSlider = _resolveComponent("ActivitiesSlider")!
  const _component_InformationCards = _resolveComponent("InformationCards")!
  const _component_OurTeam = _resolveComponent("OurTeam")!
  const _component_InvestorsAndPartners = _resolveComponent("InvestorsAndPartners")!
  const _component_RoadmapStrategy = _resolveComponent("RoadmapStrategy")!
  const _component_ContactUs = _resolveComponent("ContactUs")!
  const _component_SiteFooter = _resolveComponent("SiteFooter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_MainMenu, {
      "menu-items": _ctx.mainMenu,
      "social-networks": _ctx.socialNetworks
    }, null, 8, ["menu-items", "social-networks"]),
    _createVNode(_component_MainBanner, {
      "social-networks": _ctx.socialNetworks,
      class: "mt-5 pt-4"
    }, null, 8, ["social-networks"]),
    _createVNode(_component_ActivitiesSlider, {
      slides: _ctx.projectSlides,
      class: "rows_separator"
    }, null, 8, ["slides"]),
    _createVNode(_component_InformationCards, { class: "rows_separator" }),
    _createVNode(_component_OurTeam, {
      class: "rows_separator",
      "team-members": _ctx.teamMembers
    }, null, 8, ["team-members"]),
    _createVNode(_component_InvestorsAndPartners, { class: "rows_separator" }),
    _createVNode(_component_RoadmapStrategy, {
      points: _ctx.roadMapPoints,
      class: "rows_separator"
    }, null, 8, ["points"]),
    _createVNode(_component_ContactUs, { "social-networks": _ctx.socialNetworks }, null, 8, ["social-networks"]),
    _createVNode(_component_SiteFooter, { class: "mt-5 pt-4" })
  ], 64))
}