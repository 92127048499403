export class SocialNetworks {
    public telegram:string
    public twitter:string
    public discord:string
    public linkedin:string

    constructor(telegram:string, twitter:string, discord:string, linkedin:string) {
        this.telegram = telegram
        this.twitter = twitter
        this.discord = discord
        this.linkedin = linkedin
    }

}